/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import "./less/antMotionStyle.less";
import { Drawer, Button } from "antd";
import logoImg from "../assets/images/logo_black.png";
import axios from 'axios';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const { location = {} } = typeof window !== "undefined" ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
      drawerVisible: true,
      dashboardData: '',
    };
  }

  componentDidMount() {
    // mapbox
    mapboxgl.accessToken =
      "pk.eyJ1Ijoic3RhcnBvd2VyMTAwMCIsImEiOiJjbHF6NWF6amkwMXFrMnJwcWxsMmtzNnpqIn0.ZKvlgyGdlaFmviObw2sUvg";
    const map = new mapboxgl.Map({
      container: "starpowerMap", // container ID
      // style: "mapbox://styles/starpower1000/clu7qt5ai00yu01qs3sg0e9do",
      style: "mapbox://styles/starpower1000/clwlr3j5o00wl01r0gfb20rds",
      center: [-74.5, 40], // starting position [lng, lat]
      zoom: 2, // starting zoom
      minZoom: 0,
      minPitch: 0,
      maxZoom: 10.6,
      maxPitch: 10.6,
      logo: false,
    });
    axios.get('/starpower/manage/api/statics/board').then((res) => {
      this.setState({
        dashboardData: res.data.data
      })
      // document.getElementById('itemCity').textContent = 76;
      console.log(this.state.dashboardData);
    });
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    window.scrollTo(0, 0);
  }
  switchDrawer = () => {
    this.setState({
      drawerVisible: !this.state.drawerVisible,
    });
  }
  onClose = () => {
    this.setState({
      drawerVisible: false,
    });
  };
  getContainer = () => {
    return document.getElementById("starpowerMap");
  };
  render() {
    return (
      <div
        id="ExplorerMap"
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        <div className="mapbox-map" id="starpowerMap">
          <Button className="show-drawer" icon="unordered-list" onClick={()=>{this.switchDrawer()}}></Button>
          <Drawer
            title=""
            width="300"
            height="120"
            placement={isMobile ? 'bottom' : 'left'}
            closable={false}
            onClose={this.onClose}
            mask={false}
            getContainer={false}
            visible={this.state.drawerVisible}
            style={{ position: 'absolute', zIndex: '1' }}
          >
            <div className="info-item">
              <div className={`info-item-title`}>APP Users</div>
              {/* <div className={`info-item-data`}>{this.state.dashboardData.appUsers || 0}</div> */}
              <div className={`info-item-data`}>80k+</div>
            </div>
            <div className="info-item">
              <div className={`info-item-title`}>Daily Active Users</div>
              {/* <div className={`info-item-data`}>{this.state.dashboardData.dailyActiveUsers || 0}</div> */}
              <div className={`info-item-data`}>5k+</div>
            </div>
            {/* <div className="info-item"> */}
              {/* <div className={`info-item-title`}>Devices Sold</div> */}
              {/* <div className={`info-item-data`}>{this.state.dashboardData.soldDevices || "10k+"}</div> */}
              {/* <div className={`info-item-data`}>10k+</div> */}
            {/* </div> */}
            <div className="info-item">
              <div className={`info-item-title`}>Devices Activated</div>
              <div className={`info-item-data`}>{this.state.dashboardData.activatedDevices || '14023'}</div>
              {/* <div className={`info-item-data`}>8257</div> */}
            </div>
            <div className="info-item">
              <div className={`info-item-title`}>Cities</div>
              <div className={`info-item-data`}>781+</div>
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}
