/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import logoImg from "../assets/images/logo_black.png";
import "./less/home.less";
import { Link } from "react-router-dom";
import { Modal, Radio, Icon, Button } from "antd";
import { radioStyle, itemList, swiperFirst, eventsList } from "./data.source";

const video1 = require("../assets/images/presale/earth 2.mp4");

const partner1 = require("../assets/images/presale/logo1.png");
const partner2 = require("../assets/images/presale/logo2.png");
const partner3 = require("../assets/images/presale/logo3.png");
const partner4 = require("../assets/images/presale/logo4.png");
const partner5 = require("../assets/images/presale/logo5.png");
const partner6 = require("../assets/images/presale/logo6.png");
const partner7 = require("../assets/images/presale/logo7.png");
const partner8 = require("../assets/images/presale/logo8.png");
const partner9 = require("../assets/images/presale/logo9.png");
const partner10 = require("../assets/images/presale/logo11.png");
const partner11 = require("../assets/images/presale/logo12.png");
const partner12 = require("../assets/images/presale/logo13.png");
const partner13 = require("../assets/images/presale/logo14.png");

const content1Logo = require("../assets/images/presale/content1.png");

const content4Img1 = require("../assets/images/presale/NUM1.png");
const content4Img2 = require("../assets/images/presale/NUM2.png");
const content4Img3 = require("../assets/images/presale/NUM3.png");

const qrcode1 = require("../assets/images/presale/Android.png");
const qrcode2 = require("../assets/images/presale/AppleQR.png");

SwiperCore.use([Autoplay, Navigation, Pagination]);

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
// const getProvider = () => {
//   if ('phantom' in window) {
//     const provider = window.phantom.solana;
//     if (provider.isPhantom) {
//       return provider;
//     }
//   }
//   window.open('https://phantom.app/', '_blank');
// };

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      isPlayed: 0,
    };
  }
  // cWallet = async () =>{
  //   if (isMobile) {
  //     https://phantom.app/ul/<version>/<method>
  //   } else {

  //   }
  //   const provider = getProvider(); // see "Detecting the Provider"
  //   try {
  //       const resp = await provider.connect();
  //       console.log(resp);
  //       const message = new Date().getTime().toString();
  //       const encodedMessage = new TextEncoder().encode(message);
  //       const signedMessage = await provider.signMessage(encodedMessage, "utf8");
  //       if (signedMessage.signature) {
  //         let signStr = JSON.stringify(signedMessage.signature)
  //         const base64String = btoa(signStr);
  //         console.log(base64String);
  //       }
  //       console.log('Solana Address:' + resp.publicKey.toString());
  //   } catch (err) {
  //       // { code: 4001, message: 'User rejected the request.' }
  //   }
  // };
  componentDidMount = () => {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // mapboxgl.accessToken =
    //   "pk.eyJ1Ijoic3RhcnBvd2VyMTAwMCIsImEiOiJjbHF6NWF6amkwMXFrMnJwcWxsMmtzNnpqIn0.ZKvlgyGdlaFmviObw2sUvg";
    // const map = new mapboxgl.Map({
    //   container: "starpowerMap", // container ID
    //   // style: "mapbox://styles/starpower1000/clu7qt5ai00yu01qs3sg0e9do",
    //   // style: "mapbox://styles/starpower1000/clv1c5udw009g01ph53acefcw",
    //   style: "mapbox://styles/starpower1000/clvwgrf7i00y801qudcts7x42",
    //   center: [-74.5, 40], // starting position [lng, lat]
    //   zoom: isMobile ? 1 : 1.7, // starting zoom
    //   minZoom: isMobile ? 1 : 1.7,
    //   minPitch: 0,
    //   maxZoom: isMobile ? 1 : 1.7,
    //   maxPitch: 0,
    //   bearing: 0,
    //   pitch: 0,
    //   logo: false,
    // });
    // function rotateMap() {
    //   let center = map.getCenter();
    //   center.lng += 3;
    //   map.easeTo({
    //     center,
    //     duratuon: 1000,
    //     easing: (n) => n,
    //   });
    // }
    // map.on("moveend", () => {
    //   rotateMap();
    // });
    // rotateMap();
    let myVideo1 = document.getElementById("jzVideo1");
    if (isMobile) {
      setTimeout(() => {
        myVideo1.play();
      }, 500);
    }
    if (this.state.isPlayed === 0) {
      myVideo1.play();
      this.setState({
        isPlayed: this.state.isPlayed + 1,
      });
    }
    window.scrollTo(0, 0);
  };
  render() {
    let { payType } = this.state;
    return (
      <div className="templates-wrapper">
        <Modal
          title=""
          width={isMobile ? "90%" : "50%"}
          centered
          footer={null}
          visible={this.state.downloadVisible}
          onCancel={() => this.cancelDownload(false)}
        >
          <div className="download-modal">
            <p>Download for {this.state.downloadTitle} mobile</p>
            <img className="download-modal-logo" src={logoImg} alt="" />
            {this.state.downloadTitle == "Apple" ? (
              <img className="download-modal-qrcode" src={qrcode2} alt="" />
            ) : (
              <img className="download-modal-qrcode" src={qrcode1} alt="" />
            )}
            <p style={{ fontWeight: "bold" }}>Your new trusted companion</p>
            <p>
              Scan the QR code or search for Starpower in the App Store or
              Google Play soon.
            </p>
          </div>
        </Modal>
        <Modal
          title="Choose payment method"
          centered
          visible={this.state.modalVisible}
          onOk={() => this.setModalVisible(true)}
          onCancel={() => this.setModalVisible(false)}
        >
          <Radio.Group
            onChange={this.changePayType}
            value={payType}
            direction="vertical"
          >
            <Radio style={radioStyle} value={1}>
              Card Pay
            </Radio>
            <Radio style={radioStyle} value={2}>
              Solana Pay
            </Radio>
          </Radio.Group>
        </Modal>

        {/* home-page */}
        {/* <Button type="primary" onClick={this.cWallet}>Connect Wallet</Button> */}
        <div className="homepage-detial">
          <div className="homepage-mapbox-mask">
            <div className="homepage-top-btn">
              <p className="homepage-btn-title">Starpower</p>
              <p className="homepage-btn-desc">
                A Decentralized Energy Network
              </p>
            </div>
          </div>
          <div className="homepage-video" id="hideTop">
            <div className="homepage-floor-video">
              <video
                className="floor-video-src"
                id="jzVideo1"
                autoPlay
                playsInline
                loop
                muted
                x5-video-player-type="h5"
                src={video1}
              />
            </div>
          </div>
          {/* <div className="homepage-mapbox" id="starpowerMap"></div> */}
          <div className="homepage-partner" id="presalePartner">
            {/* <p className="homepage-partner-title">We partner with the best.</p> */}
            <div className="homepage-partner-banner">
              <div>
                <div className="homepage-partner-list">
                  <div className="homepage-partner-item">
                    <img src={partner1} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner2} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner3} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner4} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner5} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner6} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner7} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner8} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner9} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner10} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner11} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner12} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner13} alt="" />
                  </div>
                </div>
              </div>
              <div>
                <div className="homepage-partner-list">
                  <div className="homepage-partner-item">
                    <img src={partner1} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner2} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner3} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner4} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner5} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner6} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner7} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner8} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner9} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner10} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner11} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner12} alt="" />
                  </div>
                  <div className="homepage-partner-item">
                    <img src={partner13} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="homepage-floor">
            <Swiper
              className="homepage-swiper-first"
              autoplay={{
                delay: 4000,
              }}
              loop
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
              {swiperFirst.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="sw-item">
                      <div className="sw-item-left">
                        <img src={item.imgSrc} alt="" />
                      </div>
                      <div className="sw-item-right">
                        <p className="sw-right-title">{item.title}</p>
                        <p className="sw-right-content">{item.desc}</p>
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button>LEARN MORE</button>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
              {/* <SwiperSlide style={{ width: '80%' }}><img src={grants3} alt="" /></SwiperSlide>
              <SwiperSlide style={{ width: '80%' }}><img src={grants2} alt="" /></SwiperSlide>
              <SwiperSlide style={{ width: '80%' }}><img src={grants1} alt="" /></SwiperSlide> */}
            </Swiper>
          </div>
          <div className="homepage-floor">
            <div className="homepage-content1">
              <div className="content1-left">
                <p className="content1-left-title">
                  What is <span>Starpower</span>
                </p>
                <p className="content1-left-desc">
                  Starpower connects distributed energy devices through IoT,
                  blockchain, and AI, creating a flexible network that supports
                  virtual power plants. It also serves as an energy stabilizer
                  for AI systems, helping ensure stable power supply by
                  balancing fluctuations with its decentralized energy
                  resources.
                </p>
                <Link className="presale-btn-button" to="/docs">
                  <button>Lite Paper</button>
                </Link>
              </div>
              <div className="content1-right">
                <img src={content1Logo} alt="" />
              </div>
            </div>
          </div>
          <div className="homepage-floor">
            <div className="homepage-content2">
              <p className="content2-title">Star Alliance Program</p>
              <p className="content2-desc">
                The Program focuses on deploying hardware products like
                Starbattery in regions with high Virtual Power Plants (VPPs)
                benefits to form physical nodes. Deploying users receive
                hardware subsidies funded by global miners subscribing to these
                physical nodes. This initiative supports decentralized energy
                solutions by integrating storage batteries with VPPs, enhancing
                grid stability and offering financial and environmental benefits
                to participants. Through this program, stakeholders gain from
                subsidies, increased market participation, and long-term
                revenue, promoting a zero-carbon future.
              </p>
              <div className="home-works">
                <div className="total-cube">
                  <Link to="/starbattery">
                    <div className="home-works-cube2">
                      <div className="works-cube-top">Energy Storage</div>
                      <div>
                        <span
                          style={{
                            "--i": 0,
                            "--w": "350px",
                            "--h": "150px",
                            "--y": "76px",
                            background: "linear-gradient(#6d43c0, #53318b)"
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 1,
                            "--w": "0px",
                            "--h": "150px",
                            "--y": "275px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 2,
                            "--w": "0px",
                            "--h": "150px",
                            "--y": "76px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 3,
                            "--w": "150px",
                            "--h": "150px",
                            "--y": "76px",
                            background:
                              "linear-gradient(90deg, #1f7451, #3a6d98)",
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="home-works-cube2-down">
                      <div className="works-cube-top">
                        <Icon type="plus-circle" className="breathing-button" />
                      </div>
                      <div>
                        <span
                          style={{
                            "--i": 0,
                            "--w": "150px",
                            "--h": "150px",
                            "--y": "89px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 1,
                            "--w": "180px",
                            "--h": "150px",
                            "--y": "60px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 2,
                            "--w": "0px",
                            "--h": "150px",
                            "--y": "90px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 3,
                            "--w": "180px",
                            "--h": "150px",
                            "--y": "90px",
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="cube-message cube2-message">
                      <p className="cube2-message-title">Energy Storage</p>
                      <p className="cube2-message-desc">
                        Enhances energy network stability and sustainability
                        with VPP solutions, offering financial benefits and
                        supporting a zero-carbon vision.
                      </p>
                      <p className="cube2-message-more">Learn more →</p>
                    </div>
                  </Link>
                </div>
                <div className="total-cube">
                  <Link to="/docs">
                    <div className="home-works-cube1">
                      <div className="works-cube-top">
                        Starpower
                        <br />
                      </div>
                      <div>
                        <span style={{ "--i": 0, background: "linear-gradient( #6d43c0, #53318b)" }}></span>
                        <span style={{ "--i": 1 }}></span>
                        <span style={{ "--i": 2 }}></span>
                        <span style={{ "--i": 3 }}></span>
                      </div>
                    </div>
                    <div className="cube-message cube1-message">
                      <p className="cube1-message-title">Starpower</p>
                      <p className="cube1-message-desc">
                        We are a global decentralized energy network, enabling
                        individuals and businesses to monetize their energy
                        devices by stabilizing the AI energy system.{" "}
                      </p>
                      <p className="cube1-message-more">Learn more →</p>
                    </div>
                  </Link>
                </div>
                <div className="total-cube">
                  {/* <Link to="/ev-charging"> */}
                  <div className="home-works-cube4">
                    <div
                      className="works-cube-top"
                      style={{
                        background:
                          "linear-gradient(#5599d0, #a10dff)",
                      }}
                    >
                      Solar Power
                    </div>
                    <div>
                      <span
                        style={{
                          "--i": 0,
                          "--w": "350px",
                          "--h": "150px",
                          "--y": "75px",
                          background: "linear-gradient(#6d43c0, #53318b)"
                        }}
                      ></span>
                      <span
                        style={{
                          "--i": 1,
                          "--w": "150px",
                          "--h": "150px",
                          "--y": "275px",
                        }}
                      ></span>
                      <span
                        style={{
                          "--i": 2,
                          "--w": "0px",
                          "--h": "155px",
                          "--y": "76px",
                        }}
                      ></span>
                      <span
                        style={{
                          "--i": 3,
                          "--w": "154px",
                          "--h": "150px",
                          "--y": "78px",
                          background:
                            "linear-gradient(90deg, #3a6d98, #4e1a6f)",
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="home-works-cube4-down">
                    <div
                      className="works-cube-top"
                      style={{
                        background:
                          "linear-gradient(#37f4a7, #5599d0)",
                      }}
                    >
                      {/* <Icon type="plus-circle" className="breathing-button" /> */}
                    </div>
                    <div>
                      <span
                        style={{
                          "--i": 0,
                          "--w": "0px",
                          "--h": "150px",
                          "--y": "76px",
                        }}
                      ></span>
                      <span
                        style={{
                          "--i": 1,
                          "--w": "180px",
                          "--h": "150px",
                          "--y": "60px",
                        }}
                      ></span>
                      <span
                        style={{
                          "--i": 2,
                          "--w": "150px",
                          "--h": "150px",
                          "--y": "90px",
                        }}
                      ></span>
                      <span
                        style={{
                          "--i": 3,
                          "--w": "180px",
                          "--h": "150px",
                          "--y": "90px",
                          background:
                          "linear-gradient(90deg, #1f7451, #3a6d98)",
                        }}
                      ></span>
                    </div>
                  </div>
                  <div
                    className="cube-message cube4-message"
                    style={{ height: "210px" }}
                  >
                    <p className="cube4-message-title">
                      Solar Power Generation
                    </p>
                    <p className="cube4-message-desc">
                      StarPV harnesses the power of solar energy through
                      advanced technology, offering seamless integration into
                      decentralized energy systems and rewarding users for
                      supporting a sustainable, zero-carbon future.
                    </p>
                    <p className="cube4-message-more">Coming Soon</p>
                  </div>
                  {/* </Link> */}
                </div>
                <div className="total-cube">
                  <Link to="/ev-charging">
                    <div className="home-works-cube5">
                      <div
                        className="works-cube-top"
                        style={{
                          background:
                          "linear-gradient(#5599d0, #a10dff)",
                        }}
                      >
                        EV Charging
                      </div>
                      <div>
                        <span
                          style={{
                            "--i": 0,
                            "--w": "350px",
                            "--h": "150px",
                            "--y": "76px",
                            background: "linear-gradient(#6d43c0, #53318b)"
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 1,
                            "--w": "0px",
                            "--h": "150px",
                            "--y": "275px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 2,
                            "--w": "0px",
                            "--h": "150px",
                            "--y": "76px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 3,
                            "--w": "155px",
                            "--h": "150px",
                            "--y": "78px",
                            background:
                            "linear-gradient(90deg, #3a6d98, #4e1a6f)",
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="home-works-cube5-down">
                      <div
                        className="works-cube-top"
                        style={{
                          background:
                          "linear-gradient(#37f4a7, #5599d0)",
                        }}
                      >
                        <Icon type="plus-circle" className="breathing-button" />
                      </div>
                      <div>
                        <span
                          style={{
                            "--i": 0,
                            "--w": 0,
                            "--h": "150px",
                            "--y": "76px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 1,
                            "--w": "180px",
                            "--h": "150px",
                            "--y": "60px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 2,
                            "--w": "150px",
                            "--h": "150px",
                            "--y": "90px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 3,
                            "--w": "180px",
                            "--h": "150px",
                            "--y": "90px",
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="cube-message cube5-message">
                      <p className="cube5-message-title">
                        Electric Vehicle Charging
                      </p>
                      <p className="cube5-message-desc">
                        Revolutionize home EV charging through blockchain,
                        offering smart management and token rewards within a
                        decentralized energy ecosystem.
                      </p>
                      <p className="cube5-message-more">Learn more →</p>
                    </div>
                  </Link>
                </div>
                <div className="total-cube cube3-permanent">
                  <Link to="/starplug">
                    <div className="home-works-cube3">
                      <div className="works-cube-top">Adjustable Load</div>
                      <div>
                        <span
                          style={{
                            "--i": 0,
                            "--w": "345px",
                            "--h": "149px",
                            "--y": "75px",
                            background: "linear-gradient(#6d43c0, #53318b)"
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 1,
                            "--w": "0px",
                            "--h": "150px",
                            "--y": "275px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 2,
                            "--w": "0px",
                            "--h": "150px",
                            "--y": "76px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 3,
                            "--w": "151px",
                            "--h": "150px",
                            "--y": "76px",
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="home-works-cube3-down">
                      <div className="works-cube-top">
                        <Icon type="plus-circle" className="breathing-button" />
                      </div>
                      <div>
                        <span
                          style={{
                            "--i": 0,
                            "--w": "151px",
                            "--h": "151px",
                            "--y": "90px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 1,
                            "--w": "0px",
                            "--h": "150px",
                            "--y": "60px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 2,
                            "--w": "0px",
                            "--h": "150px",
                            "--y": "90px",
                          }}
                        ></span>
                        <span
                          style={{
                            "--i": 3,
                            "--w": "181px",
                            "--h": "150px",
                            "--y": "90px",
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="cube-message cube3-message">
                      <p className="cube3-message-title">
                        Adjustable Load Management
                      </p>
                      <p className="cube3-message-desc">
                        Enables efficient energy management with adjustable
                        load, integrates smoothly into decentralized networks,
                        and rewards sustainable practices for a greener future.
                      </p>
                      <p className="cube3-message-more">Learn more →</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="homepage-floor">
            <div className="homepage-content3">
              <p className="content3-title">We are in a good business</p>
              <p className="content3-desc">
                By connecting the 3rd party energy devices, Starpower can:
                <br />
                ·coordinate larger number of energy devices.
                <br />
                ·generate application layer revenue and share with manufacturers
                & device users.
              </p>
              <div className="content3-block-title">MarketSize:</div>
              <div className="content3-block">
                <div className="content3-block-item">
                  <p>~50 billion</p>
                  <p>
                    Virtual <br />
                    Power Plant
                  </p>
                </div>
                <div className="content3-block-item">
                  <p>100 billion</p>
                  <p>
                    Carbon <br />
                    Credit
                  </p>
                </div>
                <div className="content3-block-item">
                  <p>3 T</p>
                  <p>
                    Electricity <br />
                    Market
                  </p>
                </div>
                <div className="content3-block-item">
                  <p>100 billion</p>
                  <p>
                    Software <br />
                    (Energy Data)
                  </p>
                </div>
              </div>
              <div className="content3-block-title">Networks:</div>
              <div className="content3-block">
                <div className="content3-block-item">
                  <p>27 M+ pcs</p>
                  <p>EV</p>
                </div>
                <div className="content3-block-item">
                  <p>13 M+ pcs</p>
                  <p>PV</p>
                </div>
                <div className="content3-block-item">
                  <p>2 billion pcs</p>
                  <p>Appliances</p>
                </div>
                <div className="content3-block-item">
                  <p>10 M+ pcs</p>
                  <p>Battery</p>
                </div>
              </div>
            </div>
          </div>
          <div className="homepage-floor">
            <div className="homepage-content4">
              <p className="content4-title">Starpower Roadmap</p>
              <p className="content4-desc">
                Starpower leverages a combination of decentralized physical
                infrastructure networks (DePIN) and cutting-edge virtual power
                plant (VPP) technology. Our approach centers on integrating
                distributed energy resources (DERs) such as solar panels, wind
                turbines, and energy storage systems, to create a responsive and
                dynamic energy supply capable of meeting the variable demands of
                AI technologies.
              </p>
              <div className="content4-block">
                <div className="content4-block-item">
                  <img src={content4Img1} alt="" />
                  <p>
                    Use smart plugs as the hardware access points, with tokens
                    as incentives, to quickly aggregate a large number of
                    residential appliances.
                  </p>
                </div>
                <div className="content4-block-item">
                  <img src={content4Img2} alt="" />
                  <p>
                    Open the API interface to integrate energy storage
                    batteries, and establish standard data formats for
                    system-wide sharing based on the volume of users.{" "}
                  </p>
                </div>
                <div className="content4-block-item">
                  <img src={content4Img3} alt="" />
                  <p>
                    Open-source transmission protocols, making Starpower one of
                    the standards for building the energy internet.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="homepage-testimonials">
            <div className="homepage-testimonials-title">Testimonials</div>
            <div className="homepage-testimonials-banner">
              <div className="homepage-testimonials-loop">
                <div>
                  <div className="homepage-testimonials-list">
                    {itemList.map((item, index) => {
                      return (
                        <div className="testimonials-list-item" key={index}>
                          <div className="testimonials-item-info">
                            {item.desc}
                          </div>
                          <div className="testimonials-item-person">
                            <img src={item.imgSrc} alt="" />
                            <div className="item-person-detail">
                              <p>{item.name}</p>
                              <p>{item.position}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <div className="homepage-testimonials-list">
                    {itemList.map((item, index) => {
                      return (
                        <div className="testimonials-list-item" key={index}>
                          <div className="testimonials-item-info">
                            {item.desc}
                          </div>
                          <div className="testimonials-item-person">
                            <img src={item.imgSrc} alt="" />
                            <div className="item-person-detail">
                              <p>{item.name}</p>
                              <p>{item.position}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="homepage-events">
            <div className="homepage-events-title">Events around the world</div>
            <div className="homepage-events-banner">
              <div className="homepage-events-loop">
                <div>
                  <div className="homepage-events-list">
                    {eventsList.map((item, index) => {
                      if (item.type == "blockTwo") {
                        if (item.itemsTypes == "top") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-top-top">
                                <p>{item.items[0].title}</p>
                                <p>{item.items[0].desc}</p>
                              </div>
                              <div className="blockTwo-top-bottom">
                                <img src={item.items[1].imgSrc} alt="" />
                              </div>
                            </div>
                          );
                        } else if (item.itemsTypes == "down") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-down-top">
                                <img src={item.items[0].imgSrc} alt="" />
                              </div>
                              <div className="blockTwo-down-down">
                                <p>{item.items[1].title}</p>
                                <p>{item.items[1].desc}</p>
                              </div>
                            </div>
                          );
                        } else if (item.itemsTypes == "img") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-img">
                                <img src={item.items[0].imgSrc} alt="" />
                              </div>
                              <div className="blockTwo-img">
                                <img src={item.items[1].imgSrc} alt="" />
                              </div>
                            </div>
                          );
                        }
                      } else if (item.type == "bigImg") {
                        return (
                          <div className="events-item-bigimg" key={index}>
                            <img src={item.imgSrc} alt="" />
                          </div>
                        );
                      } else if (item.type == "halfImg") {
                        return (
                          <div className="events-item-halfImg" key={index}>
                            <img src={item.imgSrc} alt="" />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
                <div>
                  <div className="homepage-events-list">
                    {eventsList.map((item, index) => {
                      if (item.type == "blockTwo") {
                        if (item.itemsTypes == "top") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-top-top">
                                <p>{item.items[0].title}</p>
                                <p>{item.items[0].desc}</p>
                              </div>
                              <div className="blockTwo-top-bottom">
                                <img src={item.items[1].imgSrc} alt="" />
                              </div>
                            </div>
                          );
                        } else if (item.itemsTypes == "down") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-down-top">
                                <img src={item.items[0].imgSrc} alt="" />
                              </div>
                              <div className="blockTwo-down-down">
                                <p>{item.items[1].title}</p>
                                <p>{item.items[1].desc}</p>
                              </div>
                            </div>
                          );
                        } else if (item.itemsTypes == "img") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-img">
                                <img src={item.items[0].imgSrc} alt="" />
                              </div>
                              <div className="blockTwo-img">
                                <img src={item.items[1].imgSrc} alt="" />
                              </div>
                            </div>
                          );
                        }
                      } else if (item.type == "bigImg") {
                        return (
                          <div className="events-item-bigimg" key={index}>
                            <img src={item.imgSrc} alt="" />
                          </div>
                        );
                      } else if (item.type == "halfImg") {
                        return (
                          <div className="events-item-halfImg" key={index}>
                            <img src={item.imgSrc} alt="" />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
